import { gql } from '@apollo/client';

export const GET_PROFILE_DATA = gql`
  query GetProfileData {
    profile {
      id
      title
      firstName
      lastName
      email
      secondaryEmail
      mainPhone
      mobilePhone
      address1
      address2
      city
      state
      zipcode
      country
      birthdate
      emailOptOut
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateProfile($user: UserInput!) {
    updateProfile(user: $user) {
      id
      title
      firstName
      lastName
      secondaryEmail
      mainPhone
      mobilePhone
      address1
      address2
      city
      state
      zipcode
      country
      birthdate
      emailOptOut
    }
  }
`;

export const UPDATE_EMAIL_OPTOUT = gql`
  mutation updateProfile($user: UserInput!) {
    updateProfile(user: $user) {
      id
      emailOptOut
    }
  }
`;

export const UPDATE_USER_PRIVACY_POLICY = gql`
  mutation updateProfile($user: UserInput!) {
    updateProfile(user: $user) {
      id
      privacyPolicyAcceptanceDate
    }
  }
`;

export const UPDATE_USER_DOCS_SUBMISSION_DATE = gql`
  mutation updateProfile($user: UserInput!) {
    updateProfile(user: $user) {
      id
      financialDocumentSubmissionDate
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String
    $newPassword: String
    $username: String
  ) {
    ChangePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      username: $username
    ) {
      status
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation changeEmail(
    $currentPassword: String
    $newEmail: String
    $username: String
  ) {
    ChangeEmail(
      currentPassword: $currentPassword
      newEmail: $newEmail
      username: $username
    ) {
      id
      email
    }
  }
`;

export const GET_USER_CAMPAIGNS = gql`
  query userCampaigns {
    userCampaigns {
      registeredCampaigns {
        campaignId
      }
    }
  }
`;

export const GET_USER_REGISTERED_CAMPAIGNS = gql`
  query userRegisteredCampaigns {
    userRegisteredCampaigns {
      registeredCampaigns {
        campaignId
      }
    }
  }
`;

export const GET_USER_REGISTERED_CAMPAIGNS_DETAILS = gql`
  query userRegisteredCampaigns {
    userRegisteredCampaigns {
      registeredCampaigns {
        campaignId
        auctionDates {
          auctionStartDate
          auctionEndDate
        }
        auctionName
        biddingMethod
        registrationDate
      }
    }
  }
`;

export const DECRYPT_EMAIL = gql`
  mutation DecryptEmail($encryptedHex: String) {
    DecryptEmail(encryptedHex: $encryptedHex) {
      email
      token
    }
  }
`;

export const CREATE_VERIFIED_ACCOUNT = gql`
  mutation createVerifiedAccount(
    $email: String
    $password: String
    $token: String
  ) {
    createVerifiedAccount(email: $email, password: $password, token: $token) {
      userId
    }
  }
`;

export const CHECK_USER_EXISTS_AUTH0 = gql`
  query checkUserExistsInAuth0($email: String) {
    checkUserExistsInAuth0(email: $email)
  }
`;
